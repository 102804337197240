export default function searchModal() {
  // Search modal:
  const siteOverlayNode = document.querySelector(".site-overlay");
  const searchToggle = document.querySelector("#desktopModalTrigger");
  const searchModal = document.querySelector("#modal");
  const searchModalClose = searchModal.querySelector("#closeModalTrigger");

  let focusedElementBeforeModal;

  if (searchToggle) {
    searchToggle.addEventListener("click", () => {
      focusedElementBeforeModal = document.activeElement;

      openModal();

      function openModal() {
        siteOverlayNode.classList.add("active");
        document.body.classList.add("freeze-scroll");
        searchModal.classList.add("active");
        searchToggle.setAttribute("aria-expanded", "true");
        const searchForm = document.querySelector("#searchForm");
        const searchInput = document.querySelector("#search-field");
        searchInput.focus();
        // if we press escape, close the modal
        document.addEventListener("keydown", (e) => {
          if (e.key === "Escape") {
            closeModal(focusedElementBeforeModal);
          }
        });
        // if we click outside the modal, close the modal and put focus back on the trigger
        siteOverlayNode.addEventListener("click", () => {
          closeModal(focusedElementBeforeModal);
        });
        // if we click the close button, close the modal and put focus back on the trigger
        searchModalClose.addEventListener("click", () => {
          closeModal(focusedElementBeforeModal);
        });
        // If we tab out of the searchModalClose button, set the focus to the search input
        searchModalClose.addEventListener("keydown", (e) => {
          if (e.key === "Tab" && !e.shiftKey) {
            prevventDefault();
            searchInput.focus();
          }
        });
      }
      function closeModal() {
        siteOverlayNode.classList.remove("active");
        document.body.classList.remove("freeze-scroll");
        searchModal.classList.remove("active");
        searchToggle.setAttribute("aria-expanded", "false");
        focusedElementBeforeModal.focus();
      }
    });
  }
}
