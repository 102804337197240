export default function dropdownToggle() {
  // Get all dropdownsToggle nodes on the page:
  const dropdownToggles = document.querySelectorAll(".dropdown-toggle");

  function handleDropdowns() {
    // if there are no dropdowns present in the DOM, return early:
    if (!dropdownToggles.length)
      return console.log("No dropdownpanels present in the DOM");

    dropdownToggles.forEach((dropdownToggle) => {
      const dropdownButtons = dropdownToggle.querySelectorAll("h3 > button");

      dropdownButtons.forEach((dropdownButton) => {
        dropdownButton.addEventListener("click", (event) => {
          const clickedDropdownButton = event.target;

          if (clickedDropdownButton.getAttribute("aria-expanded") === "true") {
            collapseDropdown(clickedDropdownButton);
            return;
          }
          expandDropdown(clickedDropdownButton);
        });
      });
    });
  }
  handleDropdowns(dropdownToggles);

  // function to expand a dropdown:
  function expandDropdown(dropdownButton) {
    const dropdownButtonParent = dropdownButton.parentNode;
    const dropdownPanel = dropdownButtonParent.nextElementSibling;
    dropdownPanel.classList.add("expanded");
    dropdownButton.setAttribute("aria-expanded", "true");
  }

  // function to collapse a dropdown:
  function collapseDropdown(dropdownButton) {
    const dropdownButtonParent = dropdownButton.parentNode;
    const dropdownPanel = dropdownButtonParent.nextElementSibling;
    dropdownPanel.classList.remove("expanded");
    dropdownButton.setAttribute("aria-expanded", "false");
  }
}
