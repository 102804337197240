export default function newCategoryDropdownToggle() {
  const categorDropdownComponents = document.querySelectorAll(
    ".dropdown-accordion"
  );

  function handleCategoryDropdowns() {
    // if we have no category dropdowns, return early:
    if (!categorDropdownComponents.length)
      return console.log("No NEW category dropdowns present in the DOM");

    categorDropdownComponents.forEach((component) => {
      const dropdownPanelButtonNodes =
        component.querySelectorAll(".panel-toggle");

      dropdownPanelButtonNodes.forEach((button) => {
        button.addEventListener("click", (event) => {
          const clickedButton = event.target;
          if (clickedButton.getAttribute("aria-expanded") === "true") {
            closeAllOpenPanels();
          }
          if (clickedButton.getAttribute("aria-expanded") === "false") {
            closeAllOpenPanels();
            openClickedPanel(clickedButton);
          }

          function closeAllOpenPanels() {
            // Loop through and close all the panels:
            dropdownPanelButtonNodes.forEach((button) => {
              const parentNode = button.parentNode;
              const panelNode = parentNode.nextElementSibling;
              panelNode.classList.remove("expanded");
              button.classList.remove("active");
              button.setAttribute("aria-expanded", "false");
            });
          }

          function openClickedPanel(clickedButton) {
            const parentNode = clickedButton.parentNode;
            const panelNode = parentNode.nextElementSibling;
            clickedButton.classList.add("active");
            panelNode.classList.add("expanded");
            clickedButton.setAttribute("aria-expanded", "true");
          }
        });
      });

      const SubPanelButtons = component.querySelectorAll(".sub-panel-toggle");
      SubPanelButtons.forEach((button) => {
        button.addEventListener("click", (event) => {
          const buttonNode = event.currentTarget;
          const parentNode = buttonNode.parentNode;
          const panelNode = parentNode.nextElementSibling;
          panelNode.classList.toggle("expanded");
          buttonNode.classList.toggle("active");
          buttonNode.setAttribute(
            "aria-expanded",
            buttonNode.getAttribute("aria-expanded") === "true"
              ? "false"
              : "true"
          );
          if (panelNode.style.maxHeight) {
            panelNode.style.maxHeight = null;
          } else {
            panelNode.style.maxHeight = panelNode.scrollHeight + "px";
          }
        });
      });
    });
  }
  handleCategoryDropdowns(categorDropdownComponents);
}
